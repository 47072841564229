export default {
  data() {
    return {
      rules: {
        range(from, to) {
          return (v) => {
            if (Array.isArray(v) || (v instanceof Number || typeof v === 'number')) {
              return (v >= from && v <= to) || (`The number should be between ${from} and ${to}`);
            }
            return (v >= from && v <= to) || (`The number should be between ${from} and ${to}`);
          };
        },
        minLength(min) {
          return (v) => {
            if (Array.isArray(v) || (v instanceof String || typeof v === 'string')) {
              return (v.length >= min) || (`The value should contain at least ${min} characters.`);
            }
            return (`The value should contain at least ${min} characters.`);
          };
        },
        maxLength(max) {
          return (v) => {
            if (Array.isArray(v) || (v instanceof String || typeof v === 'string')) {
              return (v.length <= max) || (`The value should contain at maximum ${max} characters.`);
            }
            return (`The value should contain at maximum ${max} characters.`);
          };
        },
        noEmptyArray() {
          return (v) => (Array.isArray(v) && v.length !== 0) || ('One of these Options should be selected.');
        },
        textFieldRequired() {
          return (v) => {
            if (Array.isArray(v) || (v instanceof Number || typeof v === 'number')) {
              return (v?.length !== 0) || ('Field is required.');
            }
            return (v?.length !== 0) || ('Field is required.');
          };
        },
        noSpecialCharacters() {
          return (v) => !/[*?\\/|":<>]/.test(v) || 'The value cannot contain any of the following characters: \\ / : * ? " < > |';
        },
      },
    };
  },
};
